@import '../../theme/core.scss';

.root1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  background: $gradient-pink-purple;
  background: #fff;
  @include tablet-or-less {
    flex-direction: column;
  }
}
.root {
}

.about_row {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 30px 0;
  font-size: 14px;
  margin-left: -15px;
  margin-right: -15px;

  &__col {
    flex: 1;
    padding: 0 15px;
    &__title {
      font-size: 18px;
      margin-bottom: 30px;
    }

    a {
      color: #000;
      display: block;
      padding: 10px 0;
      font-weight: bold;
    }
  }
}
.signup_row {
  margin: 20px 0 160px;
  background-image: linear-gradient(
    left,
    #fff,
    #fff 40%,
    #f3df4b 40%,
    #f3df4b 100%
  );
  background-image: -webkit-linear-gradient(
    left,
    #fff,
    #fff 40%,
    #f3df4b 40%,
    #f3df4b 100%
  );

  .container {
  }

  &__inner {
    padding: 40px 30px;
    background: #f3df4b;
    display: flex;

    a {
      color: #000;
      font-size: 32px;
      text-decoration: none;
      flex: 1;

      &:hover {
        text-decoration: underline;
        color: #000;
      }
    }
  }
}
