.add_vehicle {
  &_modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  &_container {
    width: 700px;
    background: #fff;
    padding: 15px;
  }
}
.field_label {
  font-size: 12px;
}
.field_region {
  width: 100%;
}
