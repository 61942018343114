@import '../../theme/core.scss';

.btn-black-fill {
  @include button-fill($dark-gray);
}

.btn-pink-fill {
  @include button-fill($pink);
}

.btn-purple-fill {
  @include button-fill($purple);
}

.btn-black-outline {
  @include button-outline($dark-gray);
}

.btn-purple-outline {
  @include button-outline($purple);
}

.btn-margin-right {
  margin-right: 15px;
}
