.main_slider_row {
  background: #f3df4b;
}
.main_slider {
  display: flex;
  height: 400px;
  align-items: center;
  font-size: 40px;
  font-weight: 600;

  .slide {
    width: 50%;

    .slide_inner {
      display: inline-block;
    }

    &:first-child .slide_inner {
      border-bottom: 3px solid #000000;
    }
  }
}
