.title {
  color: #fff;
  font-size: 40px;
  padding: 50px 0 35px;
}
.video_row {
  background-image: linear-gradient(
    top,
    #000,
    #000 40%,
    transparent 40%,
    transparent 100%
  );
  background-image: -webkit-linear-gradient(
    top,
    #000,
    #000 40%,
    transparent 40%,
    transparent 100%
  );
}
.target_video_container {
}
.target_video {
}
