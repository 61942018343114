.footer_row {
  background: #000000;
}

.footer {
  color: #ffffff;
  padding: 80px 0 50px;

  a {
    display: block;
    font-size: 13px;
    text-decoration: none;
    color: #fff;
    padding: 5px 0;
  }
  p {
    padding-bottom: 30px;
  }

  &__logo {
    font-size: 40px;
  }
  &__top {
    padding-bottom: 50px;
  }
  &__middle {
    display: flex;
    flex-wrap: wrap;

    &_col1 {
      width: 50%;
    }
    &_col2 {
      width: 25%;
    }
    &_col3 {
      width: 25%;
    }
  }
  &__bottom {
  }
}
