@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Roboto:wght@400;700&display=swap');

@import 'nprogress/nprogress';

@import 'abstracts/_functions';
@import 'abstracts/_variables';
@import 'abstracts/_mixins';

.row {
  margin-left: -15px;
  margin-right: -15px;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
